import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Icon } from './Icon'
import { Path } from './Path'

export interface Props {
  cta?: ButtonProps
  description?: string
  image?: ImageProps
  title?: string
}

export const Paragraph = memo(function Paragraph({
  cta,
  description,
  image,
  title,
}: Props) {
  return (
    <Container dial={4} row space="between" stretch tag="section" wrap>
      <LeftSide dial={4}>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}

        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}

        {cta ? (
          <FadeInUp>
            <CTA {...cta} />
          </FadeInUp>
        ) : null}
      </LeftSide>

      <RightSide dial={4}>
        {image?.src ? (
          <LazyLoadComponent threshold={600}>
            <Path URL={image.src} />
          </LazyLoadComponent>
        ) : null}

        <Icon />
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  margin: 13.125rem auto 11.875rem;
  padding: 0 8.333vw 0 15.486vw;

  @media (max-width: 1199px) {
    margin: 6.25rem auto;
    padding: 0 1.5rem;
  }
`

const LeftSide = styled(FlexBox)`
  width: 50%;
  padding-right: 8.333vw;

  @media (max-width: 1199px) {
    width: 100%;
    order: 2;
    padding-right: 0;
    text-align: center;

    > div {
      width: 100%;
    }
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.8125rem;
  font-weight: 300;
  line-height: 3.4375rem;

  @media (max-width: 1199px) {
    font-size: 2.1875rem;
    line-height: 2.625rem;
  }
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 200;
  line-height: 2.125rem;
  margin-top: 3.75rem;

  @media (max-width: 1199px) {
    font-size: 1.125rem;
    line-height: 2.125rem;
    margin-top: 1.875rem;
  }
`

const CTA = styled(Button)`
  margin-top: 4.375rem;

  @media (max-width: 1199px) {
    margin-top: 3.125rem;
  }
`

const RightSide = styled(FlexBox)`
  width: 50%;
  max-width: 33.75rem;
  padding-right: 8.333vw;

  @media (max-width: 1199px) {
    margin: 0 auto 3.125rem;
    padding-right: 0;
  }
`
