import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  URL: string
}

export const Path = memo(function Path({ URL }: Props) {
  if (!URL) {
    return null
  }

  return (
    <Container
      xmlns="http://www.w3.org/2000/svg"
      width="375.999"
      height="458.798"
      viewBox="0 0 375.999 458.798"
    >
      <defs>
        <pattern id="paragraph-pattern" width="1" height="1">
          <image
            preserveAspectRatio="xMaxYMid slice"
            width="100%"
            height="100%"
            href={URL}
          />
        </pattern>
      </defs>

      <path
        d="M263.145,8.6c-3.117-.918-6.208-1.938-9.305-2.938A173.038,173.038,0,0,0,215.5,0H202.825a186.137,186.137,0,0,0-47.745,7.648C116.329,20.67,80.159,37.891,51.981,70.8-55.325,196.129,12.478,400.434,173.2,448.7c.14.057.255.121.4.172,22.7,8.037,50.829,9.93,50.829,9.93V295.448c83.488-.612,140.289-45.221,150.156-118.058C384.875,101.474,338.061,30.632,263.145,8.6M246.9,232.413l-23.149,8.471v53.882c-57.681.816-102.711-46.852-101.2-107.363,1.447-57.917,47.3-99.32,103.418-93.367,37.158,3.939,63.825,28.6,68.3,63.162,4.544,35.176-11.007,62.735-47.369,75.215"
        fill="url(#paragraph-pattern)"
      />
    </Container>
  )
})

const Container = styled.svg`
  width: 100%;
  height: auto;
`
